<template>
  <div class="query-method-page">
    <van-nav-bar title="查询方式" left-arrow  @click-left="$router.go(-1)"/>
    <div class="content-padding">
      <section class="service-list">
        <!-- <a class="service-item" href="/services/repair-list?type=phone">
          <van-icon name="phone-circle-o" size="120"/>
          <h3>手机号查询</h3>
          <p>使用手机号进行查询</p>
        </a> -->
        <a class="service-item" href="/services/repair-list?type=no">
            <img src="https://site-cdn.huami.com/files/amazfit/mobile/assets/service/icon05.png">
            <h3>工单查询</h3>
            <p>使用服务单号/快递单号进行查询</p>
        </a>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: 'query-method',
  data () {
    return {

    }
  }
}
</script>

<style lang="scss" scoped>
.query-method-page {
  min-height: 100vh;
  background-color: #F3F3F3;

  .service-list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: auto;
  }

  .service-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    margin-bottom: 25px;
    padding: 12px 12px 0;
    width: 48%;
    height: 200px;
    margin-right: 4%;
    text-align: center;
    color: #333;
    cursor: pointer;
    background-color: #fff;

    &:nth-child(2n) {
      margin-right: 0;
    }

    h3 {
      margin: 20px 0;
      font-size: 20px;
    }

    img {
      width: 60px;
    }

    p {
      margin: 0;
    }
  }
}
</style>
